<template>
  <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true}"></v-header>
    </div>
    <div class="list">
        
        <div class="head_bar">
            <van-image class="photo" round fit="cover" :src="userinfo.imgurl" />
            <span class="name">{{userinfo.username}} <span v-if="detailpost.wonLotterySts===1" style="color:#fff;background-color:red;font-size:0.4rem;">中</span></span>
            <div class="icon_box">
            <div class="icon">
                <van-icon name="warn-o" size="20" color="#999" />
            </div>
            <div class="icon collect" @click="goCollect(detailpost)">
                <van-icon name="star-o" size="20" color="#999" />
            </div>
            </div>
        </div>
        <p>第{{detailpost.periodText}}期<span class="postTime">{{detailpost.postTime | setDateMMdd}}</span><span v-if="detailpost.roi>0" class="rateReturn">回报率:{{detailpost.roi}}%</span></p>
        <div class="contents ">
            <!-- 方案 -->
            <div >
            <div v-for="(ite,inde) in detailpost.forumDetailsModels" :key="inde">
                <!-- <div v-html="ite.contents"></div> -->
                <p :class="ite.wonLotterySts===1?'red':'black'">[{{ite.categoryName}}]</p>
                <div v-if="ite.categoryName==='杀头'||ite.categoryName==='杀百'||ite.categoryName==='杀十'||ite.categoryName==='杀尾'">
                <p v-if="ite.textIndexPoint1!==''">
                    千位：
                    <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item!==detailpost.qian&&detailpost.qian!==''?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPoint1Major!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item!==detailpost.qian&&detailpost.qian!==''?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPoint1Focus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item!==detailpost.qian&&detailpost.qian!==''?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                <p v-if="ite.textIndexPoint2!==''">
                    百位：
                    <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item!==detailpost.bai&&detailpost.bai!==''?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPoint2Major!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item!==detailpost.bai&&detailpost.bai!==''?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPoint2Focus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item!==detailpost.bai&&detailpost.bai!==''?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                <p v-if="ite.textIndexPoint3!==''">
                    十位：
                    <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item!==detailpost.shi&&detailpost.shi!==''?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPoint3Major!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item!==detailpost.shi&&detailpost.shi!==''?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPoint3Focus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item!==detailpost.shi&&detailpost.shi!==''?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                <p v-if="ite.textIndexPoint4!==''">
                    个位：
                    <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item!==detailpost.ge&&detailpost.ge!==''?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPoint4Major!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item!==detailpost.ge&&detailpost.ge!==''?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPoint4Focus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item!==detailpost.ge&&detailpost.ge!==''?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                </div>
                <div v-else-if="ite.categoryName==='死数'">
                <p v-if="ite.textIndexPointGeneral!==''">
                    
                    <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item!==detailpost.qian&&item!==detailpost.bai&&item!==detailpost.shi&&item!==detailpost.ge&&detailpost.qian!==''&&detailpost.bai!==''&&detailpost.shi!==''&&detailpost.ge!==''?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPointGeneralMajor!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item!==detailpost.qian&&item!==detailpost.bai&&item!==detailpost.shi&&item!==detailpost.ge&&detailpost.qian!==''&&detailpost.bai!==''&&detailpost.shi!==''&&detailpost.ge!==''?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPointGeneralFocus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item!==detailpost.qian&&item!==detailpost.bai&&item!==detailpost.shi&&item!==detailpost.ge&&detailpost.qian!==''&&detailpost.bai!==''&&detailpost.shi!==''&&detailpost.ge!==''?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                </div>
                <div v-else-if="ite.categoryName==='稳码'">
                <p v-if="ite.textIndexPointGeneral!==''">
                    
                    <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===detailpost.qian||item===detailpost.bai||item===detailpost.shi||item===detailpost.ge?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPointGeneralMajor!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===detailpost.qian||item===detailpost.bai||item===detailpost.shi||item===detailpost.ge?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPointGeneralFocus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===detailpost.qian||item===detailpost.bai||item===detailpost.shi||item===detailpost.ge?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                </div>
                <div v-else-if="ite.categoryName==='头尾合'">
                <p v-if="ite.textIndexPointGeneral!==''">
                    
                    <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===detailpost.qiangesum?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPointGeneralMajor!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===detailpost.qiangesum?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPointGeneralFocus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===detailpost.qiangesum?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                </div>
                <div v-else-if="ite.categoryName==='中肚合'">
                <p v-if="ite.textIndexPointGeneral!==''">
                    
                    <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===detailpost.baishisum?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPointGeneralMajor!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===detailpost.baishisum?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPointGeneralFocus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===detailpost.baishisum?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                </div>
                <div v-else-if="ite.categoryName==='二字现'">
                <p v-if="ite.textIndexPointGeneral!==''">
                    
                    <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="detailpost.winning_number_twoshow.includes(item)?'red':'black'">{{item}} </span> 
                </p>
                </div>
                <div v-else-if="ite.categoryName==='三字现'">
                <p style="word-break:break-word;" v-if="ite.textIndexPointGeneral!==''">
                    
                    <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="detailpost.winning_number_threeshow.includes(item)?'red':'black'">{{item}} </span> 
                    
                </p>
                </div>
                <div v-else>
                <p v-if="(ite.textIndexPoint1!==''&&ite.textIndexPoint1!==undefined)">
                    千位：
                    <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item===detailpost.qian?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPoint1Major!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item===detailpost.qian?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPoint1Focus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item===detailpost.qian?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                <p v-if="(ite.textIndexPoint2!==''&&ite.textIndexPoint2!==undefined)">
                    百位：
                    <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item===detailpost.bai?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPoint2Major!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item===detailpost.bai?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPoint2Focus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item===detailpost.bai?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                <p v-if="(ite.textIndexPoint3!==''&&ite.textIndexPoint3!==undefined)">
                    十位：
                    <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item===detailpost.shi?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPoint3Major!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item===detailpost.shi?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPoint3Focus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item===detailpost.shi?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                <p v-if="(ite.textIndexPoint4!==''&&ite.textIndexPoint4!==undefined)">
                    个位：
                    <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item===detailpost.ge?'red':'black'">{{item}}</span> 
                    <span v-if="ite.textIndexPoint4Major!==''">
                        主攻
                        <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item===detailpost.ge?'red':'black'">{{item}}</span> 
                        <span v-if="ite.textIndexPoint4Focus!==''">
                        重点
                        <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item===detailpost.ge?'red':'black'">{{item}}</span> 
                        </span>
                    </span>
                </p>
                </div>
                
                
            
            
            </div>
            </div>
        
            <!-- 照片 -->
            <!-- <div class="photo_box">
            <van-image class="photo" fit="cover" v-for="(row, i) in detailpost.imagesUrlArray" :key="i + 1000000" :src="row" @click="goImagePreview(item.imagesUrlArray, i )" />
            </div> -->
        </div>
        <div class="foot_bar">
            <div :class="detailpost.upCount == '1' ? 'icon Selected' : 'icon'" @click="goThumbsSts(detailpost, index)">
            <van-icon name="good-job-o" size="24" color="#999" />
            {{detailpost.upCount || 0}}
            </div>
            <div class="icon" @click="goShare()" >
            <van-icon name="share-o" size="24" color="#999" />
            {{detailpost.shareCount || 0}}
            </div>
            <div class="icon">
            <van-icon name="comment-o" size="24" color="#999" />
            {{detailpost.commentCount || 0}}
            </div>
        </div>

        <div class="comment">
            <div class="null" v-if="(comments.length===0)"><p>评论空空如也，快来抢占沙发吧！</p></div>
            <div class="notnull" v-else>
                <div class="item" v-for="(item,index) in comments" :key="index">
                    <div class="img">
                        <img :src="item.imgurl"/>
                        <p class="blue">{{item.username}}</p>
                        <p class="floot">{{(index+1)}}楼</p>
                    </div>
                    <div class="info">
                        <p class="content">{{item.content}}</p>
                        <p>{{item.date}}</p>
                    </div>
                </div>
            </div>
        </div>
            
        
    </div>
  </div>
  
</template>

<script>
import vHeader from "@/components/header.vue";
import Moment from 'moment';
import wxShare from "@/assets/js/util/wxShare.js";

export default {
    data(){
        return{
            title:this.$route.meta.title,
            postinfo:JSON.parse(this.$route.query.postinfo),
            userinfo:JSON.parse(this.$route.query.userinfo),
            detailpost:{periodText:'2815',postTime:'2022-11-10',roi:'-100',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[
            {categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'},
            {categoryName:'定头',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'',textIndexPoint4Major:'',textIndexPoint4Focus:''},
            {categoryName:'定尾',wonLotterySts:0,textIndexPoint1:'',textIndexPoint1Major:'',textIndexPoint1Focus:'',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}
            ]},
            comments:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',username:'半仙',content:'厉害我的哥',date:'2023-01-03'},
            {imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',username:'西恩',content:'厉害我的哥呀',date:'2023-01-03'},
            {imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',username:'西恩',content:'厉害我的哥呀代付款辣椒粉库拉师傅金卡的算法理发卡收到反馈独守空房那肯定收纳女啊上课的付了款',date:'2023-01-03'},
            {imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',username:'西恩',content:'厉害我的哥呀撒旦法你可拉法独食难肥卡拉是代发内送达了开发了',date:'2023-01-03'},
            {imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',username:'西恩',content:'厉害我的哥呀上岛咖啡拉速度快发多少；；阿斯蒂芬',date:'2023-01-03'},
            {imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',username:'西恩',content:'厉害我的哥呀莱克斯顿甲方啦啊啊啊发啦看卡',date:'2023-01-03'},
            ]
        }
    },
    components:{
        vHeader
    },
    filters: {
        setDateMMdd(vle) {
        if (vle) { return Moment(vle).format('MM月DD') }
        return '';
        }
    },
    mounted(){
        console.log('帖子的期号等信息：',this.postinfo,'用户信息：',this.userinfo)
    },
    methods:{
         // 收藏帖子（1）收藏（0）取消收藏
         async goCollect(item) {
        if (this.access_token) {
            const res = await this.$axios({
            method: "put",
            url: this.$API['API_FORUM_FAV_STATUS'] + '?forum_id=' + item.forumId + '&status=1',
            data: { forum_id: item.forumId, status: '1' },
            headers: { access_token: this.access_token }
            })
            if (res.data && res.data.statusCode === 200) {
            this.$toast('收藏成功');
            }
        } else {
            this.goLoginConfirm()
        }
        },
        // 点赞（1）点踩（2） 或 取消（3）
        async goThumbsSts(item, index) {
        if (this.access_token) {
            const res = await this.$axios({
            method: "put",
            url: this.$API['API_FORUM_THUMBS_STS'] + '?forum_id=' + item.forumId + '&status=1',
            data: { forum_id: item.forumId, status: '1' },
            headers: { access_token: this.access_token }
            })
            if (res.data && res.data.statusCode === 200) {
            this.postList[index].upCount = '1'
            this.$toast('点赞成功');
            }
        } else {
            this.goLoginConfirm()
        }
        },
        goShare(){
        wxShare.goShare({
            shareTitle: '测试标题',
            shareText: '测试内容',
            shareImgUrl: '/logo.jpg',
            shareUrl: document.URL
        });
        },
    }
}
</script>

<style lang="less" scoped>
*{
    padding: 0;
    margin:0;
}
p{
    font-size: 0.38rem;
}
.red{
    color:red;
}
.black{
    color:black;
}
.blue{
    color:rgba(80, 80, 230, 0.793);
}
img{
    padding: 0;
    margin: 0.1rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
}
.list {
    background-color: white;
    margin: 4px;
    padding: 10px 10px 0;
    p {
        margin: 0;
        font-size: 15px;
    }
    .head_bar {
        display: flex;
        align-items: center;
        .photo {
        width: 40px;
        height: 40px;
        }
        .name {
        font-size: 18px;
        margin-left: 10px;
        flex: 1;
        }
        .icon_box {
        display: flex;
        .icon {
            background-color: #e5e5e5;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-left: 12px;
        }
        .icon:active {
            color: white;
            background-color: #fb3e44;
        }
        }
    }
    .postTime {
        color: #999;
        margin-left: 10px;
    }
    .rateReturn{
        // margin-left: 3.8rem;
        font-size: 0.3rem;
        margin-right: 0.3rem;
        float: right;
    }
    .contents {
        padding-bottom: 10px;
        .photo_box {
        margin-top: 12px;
        .photo {
            width: 72px;
            height: 72px;
            margin: 0 8px 2px 0;
        }
        }
    }
    .foot_bar {
        display: flex;
        justify-content: space-around;
        .icon {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 15px;
        color: #999;
        }
        .Selected {
        color: #3abaf7;
        .van-icon {
            color: #3abaf7 !important;
        }
        }
    }
    .comment{
        .null{
            text-align: center;
        }
        .notnull{
            margin: 0;
            padding: 0;
            .item{
                margin: 0.2rem;
                border:1px solid black;
                .img{
                    p{
                        display: inline-block;
                        
                    }
                    .floot{
                        margin-left: 6rem;
                    }
                    
                }
                .info{
                    margin-left: 0.2rem;
                    .content{
                        margin: 0.2rem 0;
                    }
                }
            }
            
        }
    }
}
</style>